// bower:scss
@import "../bower_components/tequila.scss/tequila.scss";
// endbower

/*
 * Fraction grid v0.1.0
 * Generated by Tequila.scss
 */

$gutter: 3%;
@include namespace('col-');

// Asymmetric
@for $i from 1 through 12 {
  .col-1-#{$i} {
    @include cols(12);

    @include tequila-to(48em) {
      @include fraction(1/$i);
    }
  }
}

@for $i from 1 through 12 {
  .span-1-#{$i} {
    @include cols(12);

    @include tequila-to(48em) {
      @include fraction(1/$i, 0%, 0%);
    }
  }
}

// Symmetric
@for $i from 1 through 12 {
  .col-#{$i} {
    @include cols(12);

    @include tequila-to(48em) {
      @include cols($i);
    }
  }
}

@for $i from 1 through 12 {
  .span-#{$i} {
    @include cols(12);

    @include tequila-to(48em) {
      @include cols($i, 0%, 0%);
    }
  }
}
